import { useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'store/types/root';
import localStorageKeys from 'utils/localStorageKeys';
import { createHash } from 'utils/hashString';
import axios from 'axios-instance';
import { AlertBanner } from 'components';
import { onInfoBanner } from 'store/features/header/headerSlice';

export const AlertInfoBanner = () => {
  const [bannerData, setBannerData] = useState(null);
  const publicIp = useSelector((state: IRootState) => state.home.publicIp);
  const auth = useSelector((state: IRootState) => state.auth);
  const dispatch = useDispatch();
  const { REACT_APP_COMPANY } = process.env;

  const onClick = () => {
    const { link } = bannerData;
    if (link) {
      window.location.href = link;
    }
  };

  const onClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    // when user closes alert, hash the text of the alert and add it to localStorage
    const { text } = bannerData;
    createHash(text, 'SHA-1').then((hash) => {
      localStorage.setItem(localStorageKeys.hideAlertInfoBanner, hash);
    });
    setBannerData(null);
    dispatch(onInfoBanner(false));
  };

  const getBannerData = () => {
    let companyTag = REACT_APP_COMPANY;

    if (companyTag === 'HX') {
      companyTag = 'HRX';
    } else if (!companyTag) {
      companyTag = 'HRG';
    }

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}AlertInfoBanner`, {
        headers: { 'x-fallback-ip': publicIp },
        params: { tag: companyTag.toLowerCase() },
      })
      .then((res) => {
        const { text } = res.data;

        if (text) {
          // before showing alert, we need to check that the user hasn't already closed it
          const localStorageHash = localStorage.getItem(
            localStorageKeys.hideAlertInfoBanner
          );

          createHash(text, 'SHA-1').then((hash) => {
            // only show banner if hashes are different
            if (localStorageHash !== hash) {
              setBannerData(res.data);
              dispatch(onInfoBanner(true));
            }
          });
        }
      });
  };

  useEffect(() => {
    getBannerData();
  }, [publicIp, auth]);

  return (
    <>
      {bannerData && (
        <AlertBanner
          type={bannerData.type}
          onClose={onClose}
          onClick={onClick}
          style={{ cursor: bannerData.link ? 'pointer' : 'default' }}
        >
          {bannerData.text}
        </AlertBanner>
      )}
    </>
  );
};
